@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@700&display=swap');

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; /* Keeps background black */
    padding: 2rem 0;
}

.navbar-list {
    display: flex;
    gap: 40px; /* Spacing between items */
    list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-list li a {
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700; /* Bold text */
    text-decoration: none;
    color: white;
    position: relative;
    padding: 5px 0;
    transition: color 0.3s ease-in-out;
}

.navbar-list li a::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: #A981FE;
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.navbar-list li a:hover::after {
    width: 100%;
    left: 0;
}