body, html, .app-container {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');

body {
    font-family: 'IBM Plex Mono', monospace;
    background-color: black !important; /* Force black background */
    color: white; /* White text for contrast */
}
  /* Custom scrollbar for Chrome, Edge, and Safari */


/* Hide the scrollbar but still allow scrolling */
body {
  overflow: scroll; /* Ensure scrolling is still possible */
}

/* For webkit browsers (Chrome, Safari, newer Edge) */
body::-webkit-scrollbar {
  display: none;  /* Hide the scrollbar */
}

/* Optional: If you want to hide the scrollbar for both horizontal and vertical */
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* 
body {
  cursor: url('/assets/cursor.png'), auto; 
} */

.circle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: black;
  position: fixed; 
  top: 0;
  left: 0;
  pointer-events: none; /* Prevents interaction with other elements */
  z-index: 9999; /* Keeps it above all elements */
}

.cursor-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none; /* Ensures no interference with other UI elements */
  z-index: 9999;
}

  




  
  