header, footer {
  background-color: black; /* Black background for header and footer */
  color: white; /* White font for header and footer */
  display: flex;
  justify-content: center;
  padding: 5rem;
}
.scramble-text {
  font-size: 2.7rem; /* Set the desired font size */
  color: #DBA6F0; /* Set the desired color */
  font-weight: 800;
  display: block; /* Convert to block element */
}
.marq {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust background if needed */
  padding: 10px 0;
  z-index: 1000; /* Ensure it stays above other elements */
}


header {
  background-color: black; /* Black background for header */
  color: white; /* White font for header */
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  height: 2vh; /* Full viewport height */
  text-align: center; /* Center text horizontally */
  padding: 0px;
  margin: 10px;
}

.header-avatar-container {
  margin-top: 0%;
  display: flex; /* Make the container a flexbox */
  align-items: center; /* Vertically center the avatar and header */
  justify-content: center; /* Center the items horizontally */
  padding: 20px; /* Add padding for space around the container */
  gap: px; /* Add a gap between the avatar and header */
}

.avatar-image {
  width: 700px; /* Adjust width */
  height: auto; /* Maintain aspect ratio */
  padding-right: 0px;
  flex-shrink: 0; /* Prevent image from shrinking */
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

header h1 {
  margin-top: 0;
  margin: 0;
  font-size: 6rem; /* Increased font size */
  color: purple; /* Change the text color to purple */
  background: none; /* Remove the gradient background */
  animation: fadeInUp s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glitch {
  0% {
    text-shadow: 1px 1px 0px #ff00ff, -1px -1px 0px #00ffff;
  } 
  25% {
    text-shadow: -1px -1px 0px #ff00ff, 1px 1px 0px #00ffff;
  }
  50% {
    text-shadow: 1px -1px 0px #ff00ff, -1px 1px 0px #00ffff;
  } 
  75% {
    text-shadow: -1px 1px 0px #ff00ff, 1px -1px 0px #00ffff;
  }
  100% {
    text-shadow: 1px 1px 0px #ff00ff, -1px -1px 0px #00ffff;
  }
}

header p {
  margin-top: 1px;
  margin: 10px 0 0;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  background: linear-gradient(90deg, #030005 0%, #000000 100%);
  border-radius: 120px;
  padding: 10px 20px;
  display: inline-block;
  color: rgb(255, 255, 255);
  animation: fadeIn 0.5s ease-out; /* Initial fade-in effect */
}

header p.glitch {
  animation: glitch 4.5s infinite alternate; /* Apply glitch effect when active */
}
@media only screen and (max-width: 480px) {
  body, html {
      font-size: 14px;
  }

  /* Remove navbar on small screens */
  .navbar {
      display: none;
  }

  /* Projects grid in 1 column on mobile */
  .projects-grid {
      grid-template-columns: 1fr; /* 1 column layout for mobile */
  }

  /* Adjust header */
  header {
      height: auto; /* Allow header to adjust its height based on content */
      padding: 20px; /* Add padding to the header */
      text-align: center; /* Center text horizontally */
  }

  header h1 {
      font-size: 2.5rem; /* Increased font size for mobile */
  }

  header p {
      font-size: 1.2rem; /* Increased font size for mobile */
  }

  h2 {
      font-size: 1.2rem;
  }

  p {
      font-size: 1rem;
  }

  .project-image {
      width: 100%;
      height: auto;
  }
}
@media only screen and (max-width: 875px) {
  .avatar-image {
      display: none; /* Hide the image */
  }
}